<template>
  <app-sierotki v-slot="{ fix }">
    <p
      class="font-weight-medium"
      v-html="fix(`
        O konsultacji u psychiatry myślimy najczęściej, kiedy poważne zaburzenie nastroju,
        poczucie osłabienia i braku energii, niepewność lub lęk, problemy ze spaniem lub apetytem,
        dziwne myśli czy nietypowe przeżycia – wypełniają znaczną część naszej życiowej przestrzeni
        i zaczynają zaburzać nasze codzienne funkcjonowanie. Pierwsza wizyta u lekarza psychiatry
        dla wielu osób w psychicznym kryzysie bywa przeżyciem dość stresującym. Ale nie musi tak być.
        Wystarczy się do niej dobrze przygotować.
    `)"
    />

    <p
      v-html="fix(`
        <strong>Po pierwsze</strong> – podczas rejestracji telefonicznej, SMS-owej lub e-mailowej
        zaznacz bardzo krótko czego dotyczy Twój problem i zapytaj czy powinieneś się do wizyty konkretnie przygotować lub coś ze sobą zabrać;
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po drugie</strong> – przygotuj i zabierz ze sobą wszystkie istotne dokumenty medyczne:
        karty informacyjne z leczenia szpitalnego (również niepsychiatrycznego),
        wyciągi z dokumentacji ambulatoryjnej (o ile je masz),
        otrzymane od innych lekarzy pisemne zalecenia,
        wyniki badań laboratoryjnych (nie starsze niż 6 miesięcy) i obrazowych (nie starsze niż 12 miesięcy)
        oraz wszelkie zaświadczenia i orzeczenia dotyczące Twojego stanu zdrowia
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po trzecie</strong> – przygotuj listę wszystkich (również niepsychiatrycznych)
        przyjmowanych obecnie leków oraz leków przyjmowanych przewlekle w przeszłości (szczególnie psychiatrycznych)
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po czwarte</strong> – przygotuj sobie (najlepiej na piśmie) listę najważniejszych
        problemów i dolegliwości, które chciałbyś zgłosić lekarzowi
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po piąte</strong> – przygotuj sobie (najlepiej na piśmie) wszystkie pytania,
        które chcesz zadać lekarzowi podczas wizyty
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po piąte</strong> – przygotuj sobie (najlepiej na piśmie) wszystkie pytania,
        które chcesz zadać lekarzowi podczas wizyty
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po szóste</strong> – upewnij się z odpowiednim wyprzedzeniem co do adresu gabinetu lub platformy online,
        na której będzie się odbywało spotkanie, sprawdź jakość połączenia internetowego,
        zabezpiecz sobie komfortowe i dyskretne warunki do konsultacji
      `)"
    />

    <p
      v-html="fix(`
        <strong>Po siódme</strong> – zasięgnij opinii o lekarzu od osób, które miały z nim kontakt,
        jednak nie opieraj się za mocno na zdaniu wyrażanym przez obce osoby bezosobowo w Internecie;
        miej dobre nastawienie i oczekuj, że otrzymasz wsparcie i konkretną pomoc.
      `)"
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
  import thumbnail from '@/assets/blog/2024-02-05-wizyta-u-psychiatry-7-krokow-przed.jpg'

  defineOptions({
    metadata: {
      title: 'Wizyta u psychiatry – 7 kroków przed?',
      date: '2024-02-05',
      tags: ['porady'],
      readTime: 4,
      thumbnail,
      description: 'O konsultacji u psychiatry myślimy najczęściej, kiedy poważne zaburzenie nastroju, poczucie osłabienia i braku energii, niepewność lub lęk, problemy ze spaniem lub apetytem, dziwne myśli czy nietypowe przeżycia – wypełniają znaczną część naszej życiowej przestrzeni i zaczynają zaburzać nasze codzienne funkcjonowanie.',
    },
  })

  defineExpose({
    metadata: {
      title: 'Wizyta u psychiatry – 7 kroków przed?',
      date: '2024-02-05',
      tags: ['porady'],
      readTime: 4,
      thumbnail,
      description: 'O konsultacji u psychiatry myślimy najczęściej, kiedy poważne zaburzenie nastroju, poczucie osłabienia i braku energii, niepewność lub lęk, problemy ze spaniem lub apetytem, dziwne myśli czy nietypowe przeżycia – wypełniają znaczną część naszej życiowej przestrzeni i zaczynają zaburzać nasze codzienne funkcjonowanie.',
    },
  })

  definePage({
    meta: {
      layout: 'blog-post',
    },
  })
</script>
