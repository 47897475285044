<template>
  <app-sierotki v-slot="{ fix }">
    <p v-html="fix(`Rodzicielstwo to niezwykle satysfakcjonująca, ale i wymagająca rola, której towarzyszy ogromna odpowiedzialność. W dzisiejszych czasach presja na bycie „idealnym rodzicem” jest wyjątkowo duża. Wielu rodziców czuje, że musi w pełni angażować się w życie swoich dzieci, nieustannie organizując im czas, wspierając edukację i rozwój, co w połączeniu z obowiązkami zawodowymi może prowadzić do wyczerpania psychicznego.`)" />
    <p v-html="fix(`W tym kontekście kluczowe jest zrozumienie potrzeby zachowania równowagi między obowiązkami rodzicielskimi a czasem na własne potrzeby. Przewlekły stres i brak regeneracji mogą prowadzić do wypalenia rodzicielskiego, które objawia się zmęczeniem, poczuciem frustracji i obniżeniem satysfakcji z życia. Dlatego też każdy rodzic powinien nauczyć się wyznaczać granice i nie zapominać o chwilach tylko dla siebie. Warto pomyśleć o krótkich przerwach, które można przeznaczyć na relaks, spotkanie z przyjaciółmi czy po prostu chwilę ciszy i spokoju.`)" />
    <p v-html="fix(`Równowaga jest kluczowa, nie tylko dla rodziców, ale i dla dzieci, które obserwując zrównoważonych, dbających o siebie rodziców, uczą się, jak zdrowo podejść do życia. Dzięki wsparciu psychologicznemu, takim jak terapia rodzinna lub grupy wsparcia, rodzice mogą lepiej zrozumieć własne potrzeby i efektywnie radzić sobie ze stresem związanym z wychowaniem dzieci. W dłuższej perspektywie, dbanie o swoje zdrowie psychiczne pozwala pełniej cieszyć się rodzicielstwem i budować zdrowsze, bardziej harmonijne relacje w rodzinie.`)" />
  </app-sierotki>
</template>

<script lang="ts" setup>
  import thumbnail from '@/assets/blog/2024-06-01-rodzicielstwo-a-zdrowie-psychiczne.jpg'

  defineOptions({
    metadata: {
      title: 'Rodzicielstwo a zdrowie psychiczne – jak odnaleźć równowagę i nie zapominać o sobie?',
      date: '2024-06-01',
      tags: ['porady'],
      readTime: 4,
      thumbnail,
      description: 'Rodzicielstwo to niezwykle satysfakcjonująca, ale i wymagająca rola, której towarzyszy ogromna odpowiedzialność. W dzisiejszych czasach presja na bycie „idealnym rodzicem” jest wyjątkowo duża.',
    },
  })

  defineExpose({
    metadata: {
      title: 'Rodzicielstwo a zdrowie psychiczne – jak odnaleźć równowagę i nie zapominać o sobie?',
      date: '2024-06-01',
      tags: ['porady'],
      readTime: 4,
      thumbnail,
      description: 'Rodzicielstwo to niezwykle satysfakcjonująca, ale i wymagająca rola, której towarzyszy ogromna odpowiedzialność. W dzisiejszych czasach presja na bycie „idealnym rodzicem” jest wyjątkowo duża.',
    },
  })

  definePage({
    meta: {
      layout: 'blog-post',
    },
  })
</script>
