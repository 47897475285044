<template>
  <app-sierotki v-slot="{ fix }">
    <p
      class="font-weight-medium"
      v-html="fix(`
        Wizyta u psychiatry może być ważnym krokiem dla nastolatka, który boryka się z problemami emocjonalnymi, behawioralnymi czy psychicznymi.
        Jednak pojawia się pytanie – czy nastolatek powinien udać się na taką wizytę z rodzicem czy samodzielnie?
        Odpowiedź nie jest jednoznaczna i zależy od wielu czynników, w tym wieku młodego pacjenta, rodzaju problemów oraz relacji z rodzicami.
    `)"
    />

    <h3 v-html="fix('Argumenty za Obecnością Rodzica')" />
    <h4 v-html="fix('1. Wymogi formalne')" />
    <p
      v-html="fix(`
        Warto pamiętać o tym, że na diagnostykę i leczenie osoby niepełnoletniej do ukończenia 16 roku życia powinien
        wyrazić jej prawny opiekun (zazwyczaj rodzic), natomiast po ukończeniu 16 lat – konieczna jest zgoda zarówno opiekuna,
        jak i samego niepełnoletniego pacjenta; w przypadku rozbieżności zdań – obowiązująca jest wola pacjenta.
        Zgodę na leczenie swojego podopiecznego opiekunowi najłatwiej wyrazić, kiedy jest z nim obecny podczas wizyty u lekarza.
      `)"
    />

    <h4 v-html="fix('2. Wsparcie emocjonalne')" />
    <p
      v-html="fix(`
        Nastolatki mogą czuć się bezpieczniej i pewniej, mając przy sobie rodzica,
        szczególnie podczas pierwszej wizyty.
      `)"
    />

    <h4 v-html="fix('3. Pełniejszy obraz sytuacji')" />
    <p
      v-html="fix(`
        Rodzice mogą dostarczyć lekarzowi ważnych informacji na temat historii zdrowia,
        zachowania oraz zmian, których nastolatek może nie dostrzegać.
      `)"
    />

    <h4 v-html="fix('4. Współpraca w leczeniu')" />
    <p
      v-html="fix(`
        Włączenie rodziców w proces leczenia może pomóc w ustaleniu strategii wsparcia
        w domu i w szkole, co jest ważne dla efektywności terapii.
      `)"
    />

    <h3 v-html="fix(`Argumenty za Samodzielną Wizytą Nastolatka`)" />
    <h4 v-html="fix('1. Prywatność i autonomia')" />
    <p
      v-html="fix(`
        Nastolatkowie często są bardziej otwarci na rozmowę o swoich problemach,
        gdy mają pewność, że rozmawiają w poufności, bez obecności rodziców.
      `)"
    />

    <h4 v-html="fix('2. Budowanie zaufania')" />
    <p
      v-html="fix(`
        Samodzielne wizyty mogą budować zaufanie między nastolatkiem a psychiatrą,
        co jest kluczowe dla sukcesu terapii.
      `)"
    />

    <h4 v-html="fix('3. Rozwój samodzielności')" />
    <p
      v-html="fix(`
        Podejmowanie decyzji o własnym zdrowiu psychicznym to ważny krok w kierunku dorosłości i samodzielności.
      `)"
    />

    <h3 v-html="fix('Kompromisowe Rozwiązania')" />
    <h4 v-html="fix('1. Częściowa obecność rodzica')" />
    <p
      v-html="fix(`
        Rodzic może towarzyszyć nastolatkowi na początku wizyty, a następnie dać mu przestrzeń na prywatną rozmowę z lekarzem.
      `)"
    />

    <h4 v-html="fix('2. Obecność rodzica podczas pierwszej wizyty')" />
    <p
      v-html="fix(`
        Rodzic może towarzyszyć pacjentowi podczas pierwszej wizyty u lekarza, a podczas kolejnych młody pacjent może się
        pojawiać na wizytach lub łączyć się z lekarzem w trybie online samodzielnie, o ile rodzic podczas pierwszej wizyty
        nie zastrzegł konieczności każdorazowego wyrażania swojej zgody na badanie.
      `)"
    />

    <h4 v-html="fix('3. Rozmowy zespołowe')" />
    <p
      v-html="fix(`
        Niektóre sesje mogą być prowadzone z udziałem rodzica, a inne tylko z nastolatkiem, w zależności od potrzeb i etapu leczenia.
      `)"
    />

    <h4 v-html="fix('4. Równoległa komunikacja z lekarzem')" />
    <p
      v-html="fix(`
        Rodzice mogą utrzymywać niezależną od wizyt komunikację z lekarzem, aby monitorować postępy i wyrażać swoje obawy.
      `)"
    />

    <h3 v-html="fix('Podsumowanie')" />
    <p
      class="mt-7"
      v-html="fix(`
        Decyzja o tym, czy nastolatek powinien uczestniczyć w wizycie u psychiatry sam, czy z rodzicem,
        powinna być dokładnie przemyślana i dostosowana do indywidualnej sytuacji.
        Ważne jest, aby nastolatek czuł się komfortowo i bezpiecznie, a jednocześnie,
        aby rodzice mieli odpowiednią wiedzę i możliwość wsparcia w procesie leczenia.
        Niezależnie od wybranej ścieżki, najważniejsze jest dobro i zdrowie psychiczne nastolatka.
      `)"
    />
    <p
      v-html="fix(`
        Jak zawsze, pamiętaj, że każda sytuacja jest wyjątkowa i wymaga indywidualnego podejścia.
        Warto skonsultować się z lekarzem psychiatrą, aby znaleźć najlepsze rozwiązanie dostosowane
        do potrzeb Twojej rodziny.
      `)"
    />
  </app-sierotki>
</template>

<script lang="ts" setup>
  import thumbnail from '@/assets/blog/2024-03-01-nastolatek-u-psychiatry-z-rodzicem-czy-sam.jpg'

  defineOptions({
    metadata: {
      title: 'Nastolatek u psychiatry – z rodzicem czy sam?',
      date: '2024-03-01',
      tags: ['porady'],
      readTime: 7,
      thumbnail,
      description: 'Wizyta u psychiatry może być ważnym krokiem dla nastolatka, który boryka się z problemami emocjonalnymi, behawioralnymi czy psychicznymi. Jednak pojawia się pytanie – czy nastolatek powinien udać się na taką wizytę z rodzicem czy samodzielnie?',
    },
  })

  defineExpose({
    metadata: {
      title: 'Nastolatek u psychiatry – z rodzicem czy sam?',
      date: '2024-03-01',
      tags: ['porady'],
      readTime: 7,
      thumbnail,
      description: 'Wizyta u psychiatry może być ważnym krokiem dla nastolatka, który boryka się z problemami emocjonalnymi, behawioralnymi czy psychicznymi. Jednak pojawia się pytanie – czy nastolatek powinien udać się na taką wizytę z rodzicem czy samodzielnie?',
    },
  })

  definePage({
    meta: {
      layout: 'blog-post',
    },
  })
</script>
