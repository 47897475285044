<template>
  <v-footer class="flex-grow-0" color="primary-dark">
    <v-container :fluid="!appDisplay.isBigScreen.value">
      <v-row class="my-8">
        <v-col cols="12" md="4" sm="6">
          <v-list class="ml-n2" :max-width="300" variant="plain">
            <v-list-item
              :active="false"
              title="Home"
              :to="{ name: '/' }"
            />
            <v-list-item
              :active="false"
              title="Poznaj nas"
              :to="{ name: '/jak-pomagamy' }"
            />
            <v-list-item
              :active="false"
              title="Jak pomagamy"
              :to="{ name: '/jak-pomagamy', hash: '#howto' }"
            />
            <v-list-item
              :active="false"
              title="Blog"
              :to="{ name: '/blog/' }"
            />
            <v-list-item
              :active="false"
              title="FAQ"
              :to="{ name: '/', hash: '#faq' }"
            />
            <v-list-item
              :active="false"
              title="Umów wizytę"
              :to="{ name: '/umow-wizyte' }"
            />
            <v-list-item
              :active="false"
              title="Cennik"
              :to="{ name: '/umow-wizyte', hash: '#pricing' }"
            />
          </v-list>
        </v-col>
        <v-col cols="12" md="8" sm="6">
          <v-row>
            <v-col align-self="center" cols="12" md="6">
              <div class="text-grey text-body-3 mb-1">Telefon</div>
              <a class="text-subtitle-2 text-decoration-none" href="tel:+48533411899" style="color: inherit;">+48 533 411 899</a>
            </v-col>
            <v-col align-self="center" cols="12" md="6">
              <div v-if="$vuetify.display.smAndDown" class="text-grey text-body-3 mb-1">Socjale</div>
              <div class="d-flex">
                <app-socials class="ml-n3" />
                <v-spacer />
                <v-btn
                  class="hidden-sm-and-down"
                  color="white"
                  icon="mdi-arrow-up"
                  @click="goTop"
                />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-grey text-body-3 mb-1">E-mail</div>
              kontakt@psycho-medical.pl
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-grey text-body-3 mb-1">Lokalizacje</div>
              Bursaki 25, 20-150 Lublin<br>
              Lotnicza 3, 20-322 Lublin
            </v-col>
            <v-col cols="12">
              <div class="text-grey text-body-3 mb-1">Dokumenty</div>
              <v-list class="ml-n3" max-width="360" variant="plain">
                <v-list-item
                  :active="false"
                  href="/pdfs/regulamin-organizacyjny-podmiotu-leczniczego.pdf"
                  subtitle="Regulamin organizacyjny"
                  target="_blank"
                />
                <v-list-item
                  :active="false"
                  href="/pdfs/standardy-ochrony-maloletnich-w-podmiocie-leczniczym.pdf"
                  subtitle="Standardy ochrony małoletnich"
                  target="_blank"
                />
                <v-list-item
                  :active="false"
                  href="/pdfs/polityka-prywatnosci.pdf"
                  subtitle="Polityka prywatności"
                  target="_blank"
                />
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex align-center w-100 my-0 my-md-8">
        <div class="text-grey text-body-3 hidden-sm-and-down">&copy; {{ year }} — Copyright</div>
        <div class="flex-grow-1 text-center text-subtitle-2 text-uppercase">Poradnia Zdrowia Psychicznego</div>
        <div class="text-grey text-body-3 hidden-sm-and-down">&copy; {{ year }} — Copyright</div>
      </div>

      <v-img
        alt="Psycho-Medical"
        class="mx-auto my-4"
        :max-width="400"
        :src="Logo"
        width="100%"
      />

      <div class="text-grey text-body-3 hidden-md-and-up text-center my-4">&copy; {{ year }} — Copyright</div>
    </v-container>
  </v-footer>
</template>

<script setup lang="ts">
  import { useAppDisplay } from '@/lib/style'
  import Logo from '@/assets/logo-dark.svg?url'

  const appDisplay = useAppDisplay()
  const year = new Date().getFullYear()
  const goTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
</script>
