<template>
  <v-navigation-drawer
    v-model:model-value="drawer"
    :class="{
      'bg-transparent': $vuetify.display.mdAndUp,
    }"
    color="background"
    disable-resize-watcher
    disable-route-watcher
    floating
    location="end"
    :temporary="$vuetify.display.smAndDown"
    touchless
  >
    <v-list variant="plain">
      <v-list-item
        :active="false"
        title="Home"
        :to="{ name: '/' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="Poznaj nas"
        :to="{ name: '/jak-pomagamy' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="Jak pomagamy"
        :to="{ name: '/jak-pomagamy', hash: '#howto' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="Blog"
        :to="{ name: '/blog/' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="FAQ"
        :to="{ name: '/', hash: '#faq' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="Umów wizytę"
        :to="{ name: '/umow-wizyte' }"
        @click="onClick"
      />
      <v-list-item
        :active="false"
        title="Cennik"
        :to="{ name: '/umow-wizyte', hash: '#pricing' }"
        @click="onClick"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
  const drawer = inject<Ref<boolean>>('drawer')

  const onClick = () => {
    if (drawer) {
      drawer.value = false
    }
  }
</script>
