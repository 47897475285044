import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { pl } from 'vuetify/locale'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    aliases: {
      expand: 'mdi-arrow-down',
      collapse: 'mdi-arrow-up',
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          background: '#eceae6',
          'on-background': '#2c2c2c',
          'primary-dark': '#0f1a18',
          'on-primary-dark': '#eceeec',
          white: '#eceeec',
          primary: '#30544f',
          orange: '#f36e42',
          blue: '#485d76',
          purple: '#b88fc9',
          grey: '#3C403C',
        },
        variables: {
          'border-opacity': 1,
        },
      },
    },
  },
  defaults: {
    VBtn: {
      variant: 'outlined',
      rounded: true,
      color: 'black',
    },
    VExpansionPanels: {
      bgColor: 'transparent',
      flat: true,
    },
    VChip: {
      variant: 'outlined',
    },
    VCard: {
      variant: 'flat',
      color: 'transparent',
    },
    VList: {
      bgColor: 'transparent',
    },
  },
  locale: {
    locale: 'pl',
    messages: { pl },
  },
})
